import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";

const Footer: FunctionComponent = () => {
  const navigate = useNavigate();

  return (
    <footer className="self-stretch bg-white flex flex-col items-center justify-start p-20 pb-[64px] mq1200:py-8 mq1200:px-[48px] mq450:px-4 mq450:pb-6 mq450:pt-[48px] mq450:gap-[32px] box-border gap-[24px] max-w-full z-[11] text-left text-5xl text-gray-300 font-palanquin mq800:pl-10 mq800:pr-10 mq800:box-border">
      <div className="self-stretch flex flex-row mq450:flex-col items-start justify-between max-w-full gap-[20px] mq1125:flex-wrap">
       
        <div className="w-full flex flex-col items-start justify-start gap-[24px] max-w-full">
          <div className="flex flex-col gap-[16px]">
          <img
            className="w-[233.1px] h-10 relative overflow-hidden shrink-0 object-contain cursor-pointer"
            alt="Pays Solution Logo"
            src="/pays-solution--logo-poziom-1-1@2x.png"
            onClick={() => navigate('/')}
          />
          <div className="self-stretch h-6 relative tracking-[-0.04em] leading-[100%] flex items-center mq450:text-lgi mq450:leading-[19px]">
            <span>
              Pay{" "}
              <span className="text-royalblue font-medium">anyone</span>,{" "}
              <span className="text-royalblue font-medium">anywhere</span>,{" "}
              <span className="text-royalblue font-medium">anytime</span>
              <span className="text-royalblue">,</span> in seconds
            </span>
          </div>
          </div>
          <div className="font-palanquin tracking-[-0.01em] font-medium text-[18px] mq450:text-[16px]">
            <div>Solutions Technology, Dubai, UAE, Corporate Tax: 104537502700001</div>
            <div className="hidden">Corporate Tax: 104537502700001</div>
          </div>
        </div>

        <div className="hidden w-[375px] flex-col items-start justify-start gap-[16px] max-w-full text-base text-gray-200">
          <div className="relative tracking-[-0.01em] capitalize font-medium">
            Newsletter Subscribe
          </div>
          <div className="self-stretch flex flex-row items-end justify-start gap-[12px] mq450:flex-wrap">
            <div className="flex-1 rounded-29xl box-border flex flex-row items-center justify-start py-[7px] px-5 min-w-[112px] border-[2px] border-solid border-whitesmoke-200">
              <input
                className="w-full [border:none] [outline:none] font-medium font-palanquin text-base bg-[transparent] h-[29px] relative tracking-[-0.01em] text-darkgray text-left p-0"
                placeholder="Enter your email"
                type="email"
              />
            </div>
            <button className="cursor-pointer [border:none] py-[9.5px] px-5 bg-[transparent] rounded-29xl [background:linear-gradient(180deg,_#2e79ee,_#30a7f9)] flex flex-row items-center justify-center">
              <div className="relative text-base tracking-[-0.01em] font-medium font-palanquin text-white text-left">
                Subscribe
              </div>
            </button>
          </div>
        </div>
      </div>
      <div className="self-stretch flex flex-row mq450:flex-col items-center mq450:items-start justify-between max-w-full gap-[12px] mq450:gap-6 text-base text-gray-200 mq1325:flex-wrap">
        <div className="w-full flex flex-row mq450:flex-col items-start justify-start gap-[12px] mq450:order-2">
          <span className="tracking-[-0.01em] font-medium">© 2025 PaysSolutions</span>
          <span className="tracking-[-0.01em] font-medium mq450:hidden">·</span>
          <span className="tracking-[-0.01em] font-medium">All rights reserved</span>
          <span className="tracking-[-0.01em] font-medium mq450:hidden">·</span>
          <span className="tracking-[-0.01em] font-medium">contact@pays.solutions</span>
          <span className="tracking-[-0.01em] font-medium mq450:hidden">·</span>
          <span className="[text-decoration:underline] tracking-[-0.01em] font-medium cursor-pointer" onClick={() => navigate('/terms-and-conditions')}>Terms And Conditions</span>
          <span className="tracking-[-0.01em] font-medium mq450:hidden">·</span>
          <span className="[text-decoration:underline] tracking-[-0.01em] font-medium cursor-pointer" onClick={() => navigate('/privacy-policy')}>Privacy Policy</span>
        </div>
        <div className="flex flex-row items-center mq450:items-start justify-start gap-[12px] mq450:order-1">
          <img
            className="h-8 w-8 cursor-pointer"
            alt="Twitter"
            src="/group-1.svg"
            onClick={() => window.open('https://x.com/PaysSolutions', '_blank')}
          />
          <img
            className="h-8 w-8 cursor-pointer"
            alt="Instagram"
            src="/group-2.svg"
            onClick={() =>
              window.open('https://www.instagram.com/pays.solutions', '_blank')
            }
          />
          <img
            className="h-8 w-8 cursor-pointer"
            alt="Telegram"
            src="/group-3.svg"
            onClick={() => window.open('https://t.me/pays_solutions', '_blank')}
          />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
