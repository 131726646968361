import { FunctionComponent } from "react";

const Technology: FunctionComponent = () => {
  return (
    <section id="technology" className="self-stretch flex flex-col gap-[56px] items-center justify-between py-[96px] px-20 mq1200:py-[64px] mq450:gap-[32px] mq1200:px-[48px] mq450:px-4 mq450:py-[32px] text-[#171717] box-border max-w-full text-left text-77xl mq450:text-[48px] font-palanquin">
      <div id="top" className="flex flex-col w-full items-center gap-[24px] mq450:gap-[16px]">
        <div id="title" className="font-palanquin font-regular text-[56px] leading-[100%] tracking-[-4%] mq450:text-[28px]">Web3 Technology</div>
        <div id="subtitle" className="font-palanquin font-extralight text-[20px] leading-[150%] text-[#454545] mq450:text-[16px] text-center">
          Join Pays Solutions and discover how easy, secure, and effective your financial operations can be in the Web3 era.
        </div>
      </div>
      <div id="grid" className="grid grid-cols-3 mq450:grid-cols-2 gap-[24px] mq450:gap-[16px] text-[24px] w-full grid-rows-2 mq450:grid-rows-3 auto-rows-fr">
        {[
          { title: "Instant Global Transactions", desc: "With Pays Solutions, sending and receiving funds takes just a few seconds, no matter the location. Our products, such as Pays Wallet, Pays HR, and Pays POS, enable lightning-fast financial operations without borders, allowing your business to operate 24/7 worldwide." },
          { title: "Full Control and Security", desc: "The security of your funds is our top priority. With a self-custodial wallet, you have complete control over your finances. We utilize audited contracts and collaborate with trusted providers to ensure the highest standards of financial data protection." },
          { title: "Simplicity and No Bureaucracy", desc: "Unlike traditional financial institutions, Pays Solutions eliminates complicated administrative processes. Our intuitive tools allow for quick onboarding and easy financial management while meeting all compliance standards." },
          { title: "Limitless Opportunities", desc: "You don't have to worry about borders. Pays Solutions enables unrestricted global operations, supporting the development of international business relationships and partnerships." },
          { title: "Building Strong Business Relationships", desc: "Quick and hassle-free financial transactions help strengthen relationships with business partners. With Pays Solutions, collaboration becomes easier, faster, and more efficient." },
          { title: "Cost Savings & Efficiency", desc: "Reduce operational costs with our technology-driven solutions while ensuring speed and efficiency. Send and receive funds instantly, improving business productivity." }
        ].map((item, index) => (
          <div key={index} className="flex flex-col py-[48px] px-[48px] mq450:py-[28px] mq450:px-[28px] bg-[#F9FAFB] rounded-[24px] mq450:rounded-[12px] gap-[24px] mq450:gap-[16px] w-auto h-auto">
            <img className="h-[56px] w-[56px] mq450:h-[32px] mq450:w-[32px]" src="/icon-tech-1.svg" alt="tech icon" />
            <div id="text" className="flex flex-col gap-[16px] mq450:gap-[8px] w-full">
              <div id="title" className="font-palanquin font-regular text-[24px] mq450:text-[16px] leading-[140%] w-full">{item.title}</div>
              <div id="subtitle" className="font-palanquin font-regular text-[16px] mq450:text-[14px] leading-[150%] text-[#454545] w-full">{item.desc}</div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Technology;