import classNames from 'classnames';
import React from 'react';

type BurgerIconProps = {
  isMenuVisible: boolean;
  toggleMenu: () => void;
};

const BurgerIcon: React.FC<BurgerIconProps> = ({ isMenuVisible, toggleMenu }) => {
  return (
    <button
      onClick={toggleMenu}
      className="relative group bg-transparent z-50 cursor-pointer hidden mq450:block"
    >
      <div className="relative flex flex-row items-center gap-4">
        <div className="flex flex-col justify-between w-[20px] h-[20px] transform transition-all duration-300 origin-center overflow-hidden">
          <div
            className={classNames(
              'bg-black h-[2px] w-7 transform transition-all duration-300 origin-left',
              { 'rotate-[42deg]': isMenuVisible }
            )}
          ></div>
          <div
            className={classNames(
              'bg-black h-[2px] w-3 transform transition-all duration-300',
              { '-translate-x-10': isMenuVisible }
            )}
          ></div>
          <div
            className={classNames(
              'bg-black h-[2px] w-7 transform transition-all duration-300 origin-left',
              { '-rotate-[42deg]': isMenuVisible }
            )}
          ></div>
        </div>
      </div>
    </button>
  );
};

export default BurgerIcon;