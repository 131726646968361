import { FunctionComponent } from "react";

const Companies: FunctionComponent = () => {
  return (
    <section className="self-stretch flex flex-col items-center justify-between pt-[32px] pb-[96px] gap-[32px] mq450:gap-[16px] px-20 mq1200:pb-[64px] mq1200:px-[48px] mq1200:pt-[48px] mq450:px-4 mq450:pb-[32px] mq450:pt-[16px] text-[#171717] box-border max-w-full text-left text-77xl mq450:text-[48px] font-palanquin">
      <div id="top" className="font-palanquin font-medium text-[16px] mq450:text-[14px] uppercase">
        Our team come from companies such as
      </div>
      <div
        id="grid"
        className="grid grid-cols-4 mq450:grid-cols-2 gap-[24px] mq450:gap-[12px] text-[24px] w-full mq450:grid-rows-6 grid-rows-3"
      >
        {["google", "microsoft", "pwc", "sap", "vodafone", "jpmorgan", "worktrips", "paysafe", "ubs", "credit", "santander", "franklin"].map((company, index) => (
          <div
            key={index}
            className="flex flex-col items-center justify-center bg-[#F9FAFB] rounded-[24px] mq450:rounded-[12px] w-full h-[152px] mq450:h-[64px]"
          >
            <img
              className={`${
                company === "google" ? "mq1200:h-[40px] mq450:h-[32px]" :
                company === "microsoft" ? "mq1200:h-[40px] mq450:h-[24px]" :
                company === "pwc" ? "mq1200:h-[40px] mq450:h-[32px]" :
                company === "sap" ? "mq1200:h-[40px] mq450:h-[32px]" :
                company === "vodafone" ? "mq1200:h-[48px] mq450:h-[28px]" :
                company === "jpmorgan" ? "mq1200:h-[40px] mq450:h-[24px]" :
                company === "worktrips" ? "mq1200:h-[40px] mq450:h-[32px]" :
                company === "paysafe" ? "mq1200:h-[28px] mq450:h-[20px]" :
                company === "ubs" ? "mq1200:h-[28px] mq450:h-[32px]" :
                company === "credit" ? "mq1200:h-[56px] mq450:h-[40px]" :
                company === "santander" ? "mq1200:h-[32px] mq450:h-[24px]" : 
                company === "franklin" ? "mq1200:h-[56px] mq450:h-[32px]" : ""
              }`}
              src={`/${company}-logo.svg`}
              alt={`${company} logo`}
            />
          </div>
        ))}
      </div>
    </section>
  );
};

export default Companies;