import { FunctionComponent } from "react";

const TCComponent: FunctionComponent = () => {
  return (
    <section className="self-stretch flex flex-col gap-6 justify-center py-16 px-20 mq1200:py-6 mq1200:px-8 mq450:px-4 mq450:py-6 box-border max-w-full text-left text-45xl text-font-color-dark-800 font-palanquin mq450:box-border mq800:gap-[24px] mq800:py-[83px] mq800:px-10 mq800:box-border"> 
    <div className="flex w-full justify-center text-[20px] font-medium">TERMS AND CONDITIONS OF SERVICE</div>
    <div id="§1" className="flex flex-col gap-4">
    <div className="flex w-full justify-center text-[20px] font-medium">§1 GENERAL PROVISIONS</div>
    <div className="flex flex-col gap-4 text-[16px] font-normal">
    <div>
        1. These Regulations set forth the rules for the provision of services by electronic means by Pays Solution Technology Limited, a company incorporated and operating under the laws of the United Arab Emirates, company registration no: CL8542, registered by DIFC Free Zone with its registered office in Dubai at: Unit IH-00-VZ-01-FL-97 Level 1, Innovation Hub, Dubai International Financial Centre, Dubai, United Arab Emirates (hereinafter: “Application and Services”) managing the Application and Services, as well as the rules for the use of the Application and Services by Users and Customers. In the remainder of the Regulations, the term Application and Services written with a capital letter refers to the above-mentioned legal entity that manages the Application and Services understood as an ICT system that enables the provision of services. 
</div>
<div>
2. The Application and Services ICT system includes a set of cooperating IT devices and software, providing for the processing and storage, as well as sending and receiving data via telecommunications networks, by means of a terminal device appropriate for the type of network. 
</div>
<div>
3. The Application and Services collects personal data, the processing of which is carried out in accordance with the relevant regulations. Detailed provisions in this regard can be found in the Privacy Policy.  
</div>
<div>
4. The Application and Services provides the following services in its operations: providing the service of access to a non-custodial wallet technology solution (non-custodial wallet interface) (hereinafter: “Wallet”).
</div>
<div>
5. The Application and Services shall only provide services to entities that have  accepted these Regulations and entered into an agreement for the provision of services under the terms described in these Regulations. The services are provided, for the benefit of natural persons who are 18 years of age or older, provided that they have full legal capacity, as well as legal persons and other entities with legal capacity.
</div>
<div>
6. In these Regulations, a User shall be defined as any person who has downloaded the Application and Services application, but has not entered into  an agreement with the Application and Services for the provision of services,  while a Customer (or you) shall be understood as a User who has entered into an agreement for the provision of services. Whenever the Regulations refer to a Customer, it does not apply to a User who does not have the status of a Customer. Whenever User is mentioned, this also applies to Customer, respectively.
</div>
<div>
7. Services to Customers are provided for a fee, which is processed entirely outside of the Application and Services through external payment providers. The Application and Services do not process or manage payments within the application. All fees are thereby charged by Third-Party Services regardless of their subsequent division between Application and Services and Third-Party Services. The amount and rules for charging fees are set forth in the Table of Fees. Detailed information about the cost of services can be seen in the application, prior to the crypto assets transfer or purchase.  
</div>
<div>
8. In accordance with Article 13(1) and (2) of Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (General Data Protection Regulation, hereinafter referred to as "GDPR"), we inform you that the Controller of your personal data is Pays Solution Technology Limited (Application and Services).
</div>
<div>
9. For matters related to the protection of your personal data, you can contact the Application and Services via email:  dpo@pays.solutions or in writing at: Unit IH-00-VZ-01-FL-97 Level 1, Innovation Hub, Dubai International Financial Centre, Dubai, United Arab Emirates.
</div>
<div>
10. If you provide personal data for the purpose of participating in recruitment, we will process your personal data based on Article 6(1)(b) of the GDPR, as processing is necessary for the performance of a contract to which the data subject is a party or to take steps at the request of the data subject prior to entering into a contract, e.g., expressing the intention to conclude a contract with the Application and Services. Data additionally may be processed on the basis of on Article 6(1)(b) of the GDPR in case you consent to the processing of personal data for the purposes of direct marketing. Provision of data is voluntary, but necessary for the above-mentioned purpose. You have the right to withdraw your consent at any time, which does not affect the legality of the processing carried out on the basis of consent before its withdrawal. The Customer can manage consent for personal data processing in the application settings at any time.  As part of the application's functionality, the Customer can decide whether to give or withdraw consent to marketing.
</div>
<div>
11. Your personal data will be processed for the period required to fulfill the purposes specified above, and thereafter for the period and to the extent required by applicable law. If the processing of personal data is based on your consent, we will process the data until the consent is withdrawn.
</div>
<div>
12. Under the GDPR, you have the following rights:
<br />a) the right to access your data and receive a copy of it;
<br />b) the right to rectify (correct) your data;
<br />c) the right to erase data when there are no legal grounds or purposes for further processing;
<br />d) the right to restrict processing or object to processing;
<br />e) the right to data portability;
<br />f) the right to lodge a complaint with the supervisory authority, i.e., The DIFC Commissioner of Data Protection, P.O. Box: Level 14, The Gate, DIFC, P. O. Box 74777, Dubai, UAE 
<br />g) the right to withdraw consent for data processing.
</div>
<div>
13. The recipients of your personal data may include authorized authorities, courts, law firms, IT service providers, and other entities with whom the Application and Services has entered into appropriate data processing agreements.
</div>
<div>
14. Providing your personal data is voluntary; however, it is necessary to achieve the purposes specified above.
</div>
<div>
15. Your personal data will be processed outside the European Economic Area.
</div>
<div>
16. Your personal data will not be processed in an automated manner, including profiling.
</div>
</div>
    </div>
    <div id="§2" className="flex flex-col gap-4">
  <div className="flex w-full justify-center text-[20px] font-medium">§2 THIRD PARTY SERVICES</div>
  <div className="flex flex-col gap-4 text-[16px] font-normal">
    <div>
      1. Third-Party Services refer to independent, licensed external service providers that facilitate certain functionalities within the Application and Services, including but not limited to fiat-to-crypto transactions, and other cryptoassets financial services. The Application and Services do not control, manage, or take responsibility for the operations of Third-Party Services. Third-Party Services may provide in particular the following services:
<br /><br />1) providing custody and administration of crypto-assets on behalf of clients,
<br />2) exchange of crypto-assets for funds,
<br />3) exchange of crypto-assets for other crypto-assets,
<br />4) providing transfer services for crypto-assets on behalf of clients,
<br />5) services enabling cash to be placed on a payment account and enabling cash withdrawals from a payment account  as well as all the operations required for operating a payment account,
<br />6) execution of payment transactions, including transfers of funds on a payment account with the user’s payment service provider or with another payment service provider,
<br />7) execution of payment transactions where the funds are covered by a credit line for a payment service user,
<br />8) payment initiation services.
    </div>
    <div>
      2. Here you can check which Third-Party Services provide services to you and to what extent. 
    </div>
    <div>
      3. The Application and Services may display informational content about Third-Party Services. These information do not constitute an endorsement, guarantee, or recommendation of any Third-Party Services. Third-Party Services are integrated to the application, but they are independent entities that operate under their own terms and policies. Users should review the terms and policies of Third-Party Services before engaging with them. Acceptance of such terms and policies may be the condition for the Customer to use certain functionalities of applications provided by Third-Party Services and integrated into the Application and Services. The services of exchange of crypto assets into fiat (and vice versa) currencies are exclusively provided by Third-Party Services and not by the Application and Services.

    </div>
    <div>
      4. The Application and Services do not provide payment or banking services, including but not limited to maintaining a payment or bank account, processing fiat transactions, or enabling deposits and withdrawals of the Customer's fiat funds. Such services, if required, are provided by Third-Party Services or other entities, which provide such services, but are not integrated with the Application and Services. 
    </div>
    <div>
      5. The Application and Services also does not provide the service of providing  information about the amount of funds held or purchased by the Customer in fiat or crypto assets. The Application and Services is also not a market maker nor does it play on the rates of crypto assets.
    </div>
  </div>
    </div>
    <div id="§3" className="flex flex-col gap-4">
  <div className="flex w-full justify-center text-[20px] font-medium">§3 TECHNICAL REQUIREMENTS</div>
  <div className="flex flex-col gap-4 text-[16px] font-normal">
    <div>
    1. The Application and Services can only be used if you have a device with Internet access. Application and Services may only be used via a mobile application, the User is required to have an android or ios mobile device, the Application and Services does not guarantee the ability to install and use the Application and Services via a mobile application if the User does not have the appropriate system updates. 
    </div>
    <div>
    2. Each User shall, on his own, be responsible for ensuring that he/she has the necessary equipment and ICT system to properly use the Application and Services.
    </div>
    <div>
      3. The Application and Services may, from time to time, make technical changes to the Application and Services, which will result in the Customer having to adapt to them. Such changes shall not be read as changes to the Regulations, and the Application and Services shall not be liable for the inability of an existing Customer to adapt to the new technical requirements. The Customer will be obliged to comply with the new technical requirements necessary for the continued use of the Application and Services as soon as they are implemented by the Application and Services.
    </div>
    <div>
      4. The Application and Services is not responsible for the acts and omissions of Third-Party Services and other entities with whom the User has entered into an agreement and whose services are used by the User in conjunction with the use of the Application and Services services.
    </div>
  </div>
    </div>
    <div id="§4" className="flex flex-col gap-4">
  <div className="flex w-full justify-center text-[20px] font-medium">§4 SERVICE PROVISION AND RISK</div>
  <div className="flex flex-col gap-4 text-[16px] font-normal">
    <div>
      1. The User's status as a Customer can be referred to with the conclusion of the agreement with the Application and Services, which occurs upon acceptance of the Regulations, other requirements, statements and consents, and completion of the registration process. Each Customer, throughout the period of having an active account, thus confirms that he/she agrees to the provisions of the Regulations in the current version.
    </div>
    <div>
      2. Each Customer, throughout the period of provision of services to him by the Application and Services, declares that he/she is aware of:
<br /><br />1) the risks associated with the use of crypto assets, which risks may result, in particular, in the loss of funds stored using the Application and Services, bearing in mind in particular: 
<br />a) legal risks related to the possibility of changes in generally applicable laws, guidelines or the issuance of an act of public authority, which may result in the blocking or confiscation by the competent authorities or bodies of funds held in crypto assets or funds exchanged for fiat and the imposition of administrative or criminal sanctions,
<br />b) the risk of hacking attacks and other technical risks that may result, in particular, in a breach of the Wallet's security and theft of funds,
<br />c) volatility risk, which may  result in a changes in the market value of crypto assets , 
<br />d) the risk of disclosure a private key or access to email to a third party;
<br /><br />2) the general risks associated with the use of the Internet, such as hacking attacks, risks of fraud and scams,
<br />- for which the Application and Services cannot be held responsible.
    </div>
    <div>
      3. The agreement is concluded for an indefinite period of time.
    </div>
    <div>
      4. In order to register on the Application and Services, the User shall provide only a valid email address. No additional personal data is required for registration. If the User chooses to engage with Third-Party Services, such providers may require additional information or KYC verification, which is handled independently by them.
    </div>
    <div>
      5. Each Customer shall be solely responsible for the use and management of his account and shall be solely liable in case of loss of access to the account or data in case of sharing a private key or an email login and password  with others or improper storage of account access data. You are responsible for protecting your data,  account elements, passkey or confidential information and sharing them with third parties at your own risk. A private key is an alphanumeric code generated by a cryptocurrency wallet. It is used to authorize transactions and prove ownership of a blockchain asset. The User, in order to create an account, receives an activation link to the email address provided, through which he/she can complete the registration process.  Hence, gaining access to the email address provided during registration by third parties may lead to such persons gaining access to the User's account and losing the funds collected, for which the User is solely responsible.
    </div>
    <div>
      6. A Customer uses the application and services by accessing the Application and Services by logging into the Application and Services dedicated application. Attempting to use the Application and Services through other applications or websites, as well as unsecured networks, may not allow proper use of the Application and Services and may result in loss of data or funds by the Customer. The Customer is also obliged to protect his payment or bank account data and not to make it available on the Application and Services.
    </div>
    <div>
      7. All terms and conditions of cooperation or individual crypto assets exchange transactions or terms and conditions of execution of individual payment services are governed exclusively by the regulations or agreements binding the User with such Third Party Services or other entities.
    </div>
    <div>
      8. Non-custodial Wallet addresses are generated and managed by Third-Party Services. The Application and Services does not create, store, or control Wallet  or private keys. Users must follow the procedures established by Third-Party Services for accessing and securing their Wallets.  Application and Services enables the display of only some stable coins that are stored on Wallet on the Base network. For other crypto assets, Application and Services does not guarantee that they will be displayed and that the Customer will be able to use them, if transferred to Wallet.
    </div>
    <div>
      9. The Customer may fund his Wallet using the methods provided by Third-Party Services. All transfers are processed exclusively through Third-Party Services and handled by selected payment operators. 
    </div>
    <div>
      10. The Application and Services do not store, manage, or process fiat currency transactions. Users who wish to engage in fiat-related operations must do so directly through Third-Party Services. The Application and Services does not control or endorse Third-Party Services and is not responsible for any issues related to their availability, security, or compliance. Agreements with individual Third-Party Services may stipulate procedures governing how to deal with unsuccessful transfer of fiat funds by providing incorrect data or technical difficulties in making a fiat transfer, or in case of analogous problems related to fiat-crypto exchange, the Application and Services shall not be liable here. Agreements with individual Third-Party Services also specify the rules for making fiat transfers, including withdrawals to the User's bank account. Individual Third-Party Services may only provide the User with a fiat-crypto exchange service or a fiat money storage service. 
    </div>
    <div>
      11. Crypto assets that can be transferred to the Customer's Wallet are not tradable financial instruments, including securities or payment instruments. The Application and Services does not provide any services in the field of financial instruments, in particular, it does not operate a trading system for such instruments, it is not a brokerage house or an investment company.
    </div>
    <div>
      12. The Application and Services also does not support or allow the User to use the Application and Services for other activities and actions (including supplementary protocols) than those expressly stated in these Regulations. In case of doubt, such activities should be considered prohibited and may become grounds for deleting the Customer's account.
    </div>
    <div>
      13. The Application and Services shall not be responsible for any services provided by Third-Party Services, including but not limited to fiat-to-crypto transactions, transaction fees, processing times, withdrawal and deposit limits, or regulatory compliance requirements. Users must review and accept the terms of Third-Party Services independently before using their services. The Application and Services do not conduct KYC verification themselves but Users may be required  to complete a KYC process through an integrated Third-Party Services when setting up an account provided by such an entity. Failure to obtain such data documents or information may result in lack of entitlement to use Third-Party Services, e.g. by topping up Wallet with funds exchanged from fiat to crypto assets, with the customer retaining the right to access the service provided by the Application and Services.
    </div>
    <div>
      14. Whenever the Regulations refer to the prevention of access to the account interface or the cessation of services, it means in particular the prevention of use of the Wallet account interface.
    </div>
  </div>
    </div>
    <div id="§5" className="flex flex-col gap-4">
  <div className="flex w-full justify-center text-[20px] font-medium">§5 PROHIBITED ACTIVITIES</div>
  <div className="flex flex-col gap-4 text-[16px] font-normal">
    <div>
    By accepting these Regulations, the User, including the Customer, agrees to:<br />
    <br />1) abide by the Regulations, in particular with respect for the Application and Services, other Users or Third-Party Services,
    <br />2) use the Application and Services without violating generally applicable laws, rules of social coexistence or good morals,
    <br />3) not use the Application and Services for hacking activities or transmission of dangerous data.
    </div>
  </div>
    </div>
    <div id="§6" className="flex flex-col gap-4">
  <div className="flex w-full justify-center text-[20px] font-medium">§6 METHODS AND CONDITIONS OF TERMINATION OF SERVICE AGREEMENT</div>
  <div className="flex flex-col gap-4 text-[16px] font-normal">
    <div>
      1. The Customer may terminate the agreement concluded by creating an account and accepting the Regulations, which is also understood as a request to delete the Customer's account, without notice at any time. Termination of the Agreement by the Customer may be done by confirming this option by clicking the specified button in the Customer's account on the Application and Services.
    </div>
    <div>
      2. Before the deletion of the account, the Customer will be required to export his private key in a manner selected by the Customer. The Application and Services is not responsible for where and how Customer exports his private key. Failure to export the private key within the indicated period, may result in the inability to regain access to the funds being stored in the Wallet.  Deletion of the account is understood as preventing the Customer from accessing the application's interface and deletion of the Customer's profile and data. However, the Application and Services to any extent does not come into possession of the funds of such Customer. The Customer 's data is stored to such an extent and for such a period of time as required by generally applicable laws.  This section shall apply to the termination of the agreement in any case described in this paragraph.
    </div>
    <div>
      3. The Customer is authorized to request the deletion of his account, which also implies termination of the agreement, through the Application and Services. Deletion of the account takes place within 14 days of notification from the Customer.
    </div>
    <div>
      4. The Application and Services may, subject to section 5, terminate the agreement and delete such account interface in case of violation of the Regulations by Customer or when there is a judgment or an authority decision or other act of state authority is issued, stating that the Customer conducts illegal activities with the use of the account on the Application and Services, or the funds that the Customer accumulates on the Wallet originate from a crime or are stored illegally.
    </div>
    <div>
      5. The Customer, in the case indicated in section 4, shall be informed of the termination of the agreement and deletion of the account immediately (no longer than within 24 hours) by communicating using the Application and Services or by email. The reason for the termination of the agreement and deletion of the account will be clearly stated. Preventing access to the Customer's account interface does not mean seizing or removing the funds available on Wallet. The Customer has the right to appeal the decision in this regard within 7 days from the date of notification of the decision to the Customer. In the event of an appeal, Application and Services will reconsider the decision made within 14 days of the receipt of the appeal and will issue a final decision.
    </div>
    <div>
      6. The Agreement may also be terminated if the Customer has failed to provide the documents requested by the Application and Services within the prescribed period, including if the need to provide the documents arose later, including due to a change in generally applicable laws or the issuance of a judgment or other act of governmental authority. Section 5 above shall apply directly.
    </div>
  </div>
    </div>
    <div id="§7" className="flex flex-col gap-4">
  <div className="flex w-full justify-center text-[20px] font-medium">§7 RESPONSIBILITY OF THE PARTIES</div>
  <div className="flex flex-col gap-4 text-[16px] font-normal">
    <div>
      1. The Customer is responsible for the actions taken on and with the use of the Application and Services, including within the functionality of the Customer's account. It is presumed that it is the Customer who is responsible for the actions that are taken using his account.
    </div>
    <div>
      2. The Customer, being aware of the risks associated with the crypto assets industry and the use of the Application and Services, is responsible for his own actions that will lead to the loss of funds. The Application and Services is not responsible for and does not guarantee the preservation of a certain value by the crypto assets held by the Customer in the Wallet.
    </div>
    <div>
      3. The Application and Services shall be liable to Users for the provision of services only to the extent permitted by generally applicable law. The Application and Services shall not be liable for the provision of services and for the acts and omissions of Third-Party Services towards Users. The User should familiarize themselves with the offer of Third-Party Services on the websites or applications of such entities. The Application and Services is not responsible for the timeliness of information about offers or agreements with such entities provided on the Application and Services. 
    </div>
    <div>
      4. The Application and Services is also not responsible for:
<br /><br />1) User's actions contrary to the law and the Regulations,
<br />2) Sharing data, including account access data with third parties,
<br />3) Deletion or loss of account data caused by the User himself or by third parties,
<br />4) technical problems related to the deposit or withdrawal of funds from the Wallet, and concerning networks, devices, applications or programs independent of the Application and Services,
<br />5) entering incorrect or mistaken data when using the account, , or making transactions,
<br />6) the length of the various activities and operations performed using the account or Wallet,
<br />7) the impossibility of carrying out transactions and taking other actions within a certain period of time, if it is related to technical limitations of carrying out such transactions or actions,
<br />8) technical limitations related to the operation of the User's devices.

    </div>
  </div>
    </div>
    <div id="§8" className="flex flex-col gap-4">
  <div className="flex w-full justify-center text-[20px] font-medium">§8 INTELLECTUAL PROPERTY</div>
  <div className="flex flex-col gap-4 text-[16px] font-normal">
    <div>
      1. The Application and Services may give permission for the User to use the logo and name of the Application and Services to inform about its activities and services. In order to obtain permission, the User will send a draft of the information planned to be published or distributed, the place where it will be available. The Application and Services will make a decision as soon as possible (no later than 14 days) at its sole discretion.
    </div>
    <div>
      2. The Application and Services, all content, logos and other materials comprising the Application and Services are the intellectual property of the Application and Services and other third parties. The User is not entitled to copy or distribute this content to any extent and in any way, without obtaining the appropriate license or transfer of rights. This applies to the Application and Services as a whole, but also to each individual element of the Application and Services, which may be considered a copyrighted work or a patent, trademark, industrial design protected by relevant laws.
    </div>
  </div>
    </div>
    <div id="§9" className="flex flex-col gap-4">
  <div className="flex w-full justify-center text-[20px] font-medium">§9 COMPLAINTS</div>
  <div className="flex flex-col gap-4 text-[16px] font-normal">
    <div>
    1. If the User becomes reasonably and documented convinced that the Application and Services violates the Regulations or the law, he/she may file a complaint by directing it to the following email address: dpo@pays.solutions. The User is obliged to indicate contact details, login, Wallet number, description of the problem and the proposed way to solve it.
    </div>
    <div>
    2. Complaints may also be filed in other cases provided by law.
    </div>
    <div>
    3. Complaints will be considered within 30 days from the date of notification. If the information presented by the User is not sufficient to consider the claim, the Application and Services will notify the User to supplement the claim. If the information is not supplemented promptly, the application will be left without consideration.
    </div>
    <div>
    4. Information on how the complaint was handled will be sent to the User at the e-mail address or through the account on the Application and Services.
    </div>
  </div>
    </div>
    <div id="§10" className="flex flex-col gap-4">
  <div className="flex w-full justify-center text-[20px] font-medium">§10 CHANGES TO THE REGULATIONS</div>
  <div className="flex flex-col gap-4 text-[16px] font-normal">
    <div>
    The current Regulations are published in the Application and Services. The Application and Services reserves the right to amend the Regulations. When the new/amended version of the Regulations is published on the Application and Services and the User is notified in the app or by email, the new/amended version of the Regulations is binding for the User within 14 days from the notification.  In the event that the User does not accept the new/changed version of the Regulations, he may terminate the Agreement on the usual terms provided for in these Regulations.
    </div>
  </div>
    </div>
    <div id="§11" className="flex flex-col gap-4">
  <div className="flex w-full justify-center text-[20px] font-medium">§11 FINAL PROVISIONS</div>
  <div className="flex flex-col gap-4 text-[16px] font-normal">
    <div>
    1. The Regulations supersede all previous arrangements between the Application and Services and the User in the provision of services described in the Regulations.
    </div>
    <div>
    2. In matters not covered by the Regulations, the laws of United Arab Emirates, Dubai International Financial Centre shall apply.
    </div>
    <div>
    3. The provided personal data of the Application and Services and the User are processed in accordance with applicable law.
    </div>
    <div>
    4. Any disputes arising out of or in connection with these Regulations shall be settled by a common court of competent jurisdiction applicable to the Application and Services, unless such jurisdiction is not permitted by generally applicable laws.
    </div>
    <div>
    5. Whenever it is permitted by generally applicable law, disputes between the Application and Services and the User will be resolved by binding arbitration. This applies to all matters that may arise against the background of the Regulations.
    </div>
  </div>
    </div>

    </section>
  );
};

export default TCComponent;
