import { FunctionComponent } from "react";
import FAQList from "./FAQList";

const FAQ: FunctionComponent = () => {
  return (
    <section id="faq" className="self-stretch flex gap-[56px] flex-col mq450:flex-col items-center justify-between py-[96px] px-20 mq1200:py-[64px] mq1200:px-[48px] mq450:px-4 mq450:py-8 mq450:gap-[32px] text-[#171717] box-border max-w-full text-left text-77xl mq450:text-[48px] font-palanquin mq450:pt-5 mq450:pb-5 mq450:box-border mq1125:pt-[21px] mq1125:pb-[21px] mq1125:box-border mq1325:pl-10 mq1325:pr-10 mq1325:box-border">
        <div id="top" className="flex flex-col w-full items-center gap-[24px]">
        <div id="title" className="font-palanquin font-regular text-[56px] leading-[100%] tracking-[-4%] mq450:text-[28px]">Frequently Asked Questions</div>
        </div>
        <FAQList />
    </section>
  );
};

export default FAQ;
