import { useState } from "react";

type FAQItemProps = {
  question: string;
  answer: string;
};

const faqData: FAQItemProps[] = [
  {
    question: "Why choose Pays Solutions?",
    answer: `Be your own boss. We’ve designed a self-custodial wallet where you remain the sole owner of your assets. This approach ensures full independence and guarantees fund ownership—even if Pays Solutions were to cease operations.<br /><br />With enhanced security, no third party can freeze or access your funds. Plus, our decentralized system allows direct interaction with blockchain applications.`,
  },
  {
    question: "How is a self-custodial wallet different from a custodial wallet?",
    answer: `<ul><li><strong>Self-Custodial Wallet:</strong> You control your private keys and are fully responsible for securing your funds.</li><li><strong>Custodial Wallet:</strong> A third-party service (such as an exchange) manages private keys on your behalf.</li></ul>`,
  },
  {
    question: "Is Pays Solutions Wallet secure?",
    answer: `Absolutely. We use an advanced MPC (Multi-Party Computation) and AA (Account Abstraction) approach to enhance security:<br /><br /><ul><li>Your private key is split into fragments—both you and Pays Solutions’ authenticator system hold parts of the key, ensuring that no single party has full access.</li><li>A portion of the key is stored on your device, while another portion is kept in Pays Solutions’ secure infrastructure.</li><li>Logging in via email, Google, or other authentication methods reconstructs access, eliminating the need to manage a seed phrase.</li></ul><br />Security & Ownership:<br /><ul><li><strong>Self-Custodial Model:</strong> You own your private key, and Pays Solutions does not have full access to it.</li><li><strong>Social Recovery Option:</strong> You can set up account recovery in case you lose access.</li><li><strong>Account Abstraction (AA) Integration:</strong> Enables automated transaction signing and enhances the user experience.</li></ul>`,
  },
  {
    question: "How do transactions work in a self-custodial wallet?",
    answer: `Since you control the private key, you must sign every transaction. Pays Solutions supports Account Abstraction (AA), enabling:<br /><ul><li>Automated gas payments</li><li>Transaction batching for a smoother experience</li></ul>`,
  },
  {
    question: "Is your wallet available as whitelabel?",
    answer: `Yes! We offer a white-label version for business clients. Our flexible integration options make it easy for companies to implement our technology seamlessly.`,
  },
  {
    question: "How can I withdraw my funds?",
    answer: `We prioritize flexibility and ease of use, continuously expanding our network of partners to support multiple withdrawal methods:<br /><ul><li>💳 Debit/Credit Card Linking</li><li>🏦 Bank Transfers</li><li>💰 Physical Cash-Based POS Systems</li></ul>`,
  },
  {
    question: "Is Pays Solutions only for businesses?",
    answer: `No! While we support B2B cross-border transactions, our wallet is also designed for individual users, making international money transfers simple and efficient.`,
  },
  {
    question: "Are there any geographical restrictions?",
    answer: `Pays Solutions Wallet is available worldwide with no restrictions. However, the availability of on/off-ramp partners may vary by region.<br /><br />Currently supported regions: Europe, the Middle East, and Far Asia.<br />We are actively working on expanding our partner network—stay updated via our website!`,
  },
];

const FAQItem: React.FC<FAQItemProps> = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div className="faq-item">
      <button
        className="flex justify-between gap-[24px] items-center w-full py-[40px] mq450:py-[32px] text-left bg-transparent font-palanquin text-[24px] no-underline visited:text-black text-black mq450:text-[16px] mq450:leading-[150%] font-medium px-0"
        onClick={() => setIsOpen(!isOpen)}
      >
        {question}
        {isOpen ? (
          <img src="/minus.svg" alt="Minus" className="w-[32px] h-[32px] mq450:w-[24px] mq450:h-[24px]" />
        ) : (
          <img src="/plus.svg" alt="Plus" className="w-[32px] h-[32px] mq450:w-[24px] mq450:h-[24px]" />
        )}
      </button>
      <div
        className={`faq-answer-wrapper transition-all duration-300 overflow-hidden ${isOpen ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'}`}
      >
        <p
          className="faq-answer text-[#454545] text-[20px] mq450:text-[16px] leading-[150%] pb-[40px] m-0 no-underline visited:text-black"
          dangerouslySetInnerHTML={{ __html: answer }}
        />
      </div>
    </div>
  );
};

const FAQList: React.FC = () => {
  return (
    <div className="flex flex-col w-full">
      {faqData.map((item, index) => (
        <div key={index}>
          <FAQItem {...item} />
          <div className="flex w-full bg-[#EFEFEF] h-[1px]"></div>
        </div>
      ))}
    </div>
  );
};

export default FAQList;