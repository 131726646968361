import { FunctionComponent } from "react";


const Hero: FunctionComponent = () => {
  return (
    <section id="home" className="self-stretch flex gap-[48px] flex-col mq450:flex-col items-center justify-between pt-[120px] pb-8 px-20 mq1200:pt-[120px] mq1200:pb-8 mq1200:px-[48px] mq450:px-4 mq450:pt-[88px] text-[#171717] box-border max-w-full text-left text-77xl mq450:text-[48px] font-palanquin mq450:pb-5 mq450:box-border mq1125:pt-[21px] mq1125:pb-[21px] mq1125:box-border mq1325:pl-10 mq1325:pr-10 mq1325:box-border mq450:gap-[24px]">
    <div className="flex flex-col gap-[12px] items-center">
    <div id="top" className="font-palanquin font-medium text-[16px] uppercase mq450:text-[14px]">The Future of Global Finance</div>
    <div className="flex flex-col gap-[32px] mq450:gap-[16px]">
    <div id="title" className="font-palanquin font-regular text-[80px] mq450:text-[32px] leading-[110%] text-center">
      <div className="flex flex-row items-center gap-3 mq450:gap-2 justify-center"><div className="font-medium" style={{ background: "linear-gradient(180deg, #2e79ee, #30a7f9)", WebkitBackgroundClip: "text", color: "transparent" }}>
      Self-Custodial </div>Wallets,</div>
      <div className="flex flex-rowitems-center gap-3 mq450:gap-2 justify-center"><div className="font-medium" style={{ background: "linear-gradient(180deg, #2e79ee, #30a7f9)", WebkitBackgroundClip: "text", color: "transparent" }}>
      White-Label </div>Banking &</div>
      <div className="flex flex-row items-center justify-center"><div className="font-medium" style={{ background: "linear-gradient(180deg, #2e79ee, #30a7f9)", WebkitBackgroundClip: "text", color: "transparent" }}>
  Remittance</div>-As-A-Service</div>
  </div>
    <div id="subtitle" className="font-palanquin font-extralight text-[20px] mq450:text-[16px] leading-[150%] text-center text-[#454545]">Pays Solutions is dedicated to revolutionizing payments in the Web3 ecosystem, providing seamless and compliant hybrid payment solutions that empower organizations and individuals globally.</div>
    </div>
    </div>
    <div className="flex flex-row items-start justify-start gap-[8px] mq450:w-full">
          <button onClick={() => window.location.href='https://meetings-eu1.hubspot.com/lspirala'} className="cursor-pointer h-[56px] mq450:h-[48px] px-[32px] mq450:w-full bg-[transparent] rounded-full [background:linear-gradient(180deg,_#2e79ee,_#30a7f9)] flex flex-row items-center justify-center whitespace-nowrap">
            <div className="flex font-palanquin font-regular text-[18px] mq450:text-[16px] items-center justify-center text-white">
            Book a Demo
            </div>
          </button>
          <button onClick={() => window.location.href = '/#products'} className="cursor-pointer h-[56px] px-[32px] bg-[transparent] mq450:h-[48px] mq450:w-full rounded-full flex flex-row items-center justify-center border-[1px] border-solid border-whitesmoke-200 hover:bg-lightgray-300 hover:box-border hover:border-[1px] hover:border-solid hover:border-lightgray-100">
            <div className="flex font-palanquin font-regular text-[18px] mq450:text-[16px] items-center justify-center text-gray-200 text-center">
            Explore
            </div>
          </button>
        </div>
    <img 
    className="w-full"
    src="/map.svg"
    />
    </section>
  );
};

export default Hero;
