import { FunctionComponent } from "react";

const Products: FunctionComponent = () => {
  return (
    <section id="products" className="self-stretch flex gap-[56px] flex-col mq450:flex-col items-center justify-between py-[96px] px-20 mq1200:py-[64px] mq1200:px-[48px] mq450:px-4 mq450:py-[32px] text-[#171717] box-border max-w-full text-left text-77xl mq450:text-[48px] font-palanquin mq450:box-border mq1125:pt-[21px] mq1125:pb-[21px] mq1125:box-border mq1325:pl-10 mq1325:pr-10 mq1325:box-border mq450:gap-[24px]">
    <div id="top" className="flex flex-col w-full items-center gap-[24px] mq450:gap-[16px]">
    <div id="title" className="font-palanquin font-regular text-[56px] leading-[100%] mq450:text-[28px]">Self-Custodial Products</div>
    <div id="subtitle" className="font-palanquin font-extralight text-[20px] leading-[150%] text-[#454545] mq450:text-[16px] mq450:text-center">Discover our innovative self-custodial financial tools designed to give you full control over your funds and payroll management.</div>
    </div>
    <div id="cards" className="flex flex-row mq450:flex-col w-full gap-[32px]">
    <div id="card-pays-remittance" className="flex flex-col w-full gap-6">
        <div id="product" className="flex flex-col items-center justify-end bg-[#F9FAFB] rounded-[24px] w-full h-[320px] pb-0 gap-[32px]">
            <img
            src="/remittance-logo.svg"
            />
            <img
            src="/wallet-img.png"
            />
        </div>
        <div id="bottom" className="flex flex-col gap-4">
        <div id="title" className="font-palanquin font-regular text-[24px] leading-[100%] mq450:text-[20px]">Pays Remittance</div>
        <div id="subtitle" className="font-palanquin font-light text-[16px] leading-[150%] text-[#454545]">A modern, secure and self-custodial wallet providing full control over funds. It offers access to payment cards and a simple interface as in traditional banking applications.</div>
        <button onClick={() => window.location.href='https://meetings-eu1.hubspot.com/lspirala'} className="cursor-pointer h-[48px] px-[24px] bg-[transparent] rounded-full flex flex-row items-center justify-center border-[1px] border-solid border-whitesmoke-200 hover:bg-lightgray-300 hover:box-border hover:border-[1px] hover:border-solid hover:border-lightgray-100">
            <div className="flex font-palanquin font-regular text-[16px] items-center justify-center text-gray-200 text-center">
              Book A Demo
            </div>
          </button>
          </div>
    </div>
    <div id="card-pays-hr" className="flex flex-col w-full gap-6">
        <div id="product" className="flex flex-col items-center justify-end bg-[#F9FAFB] rounded-[24px] w-full h-[320px] pb-0 gap-[32px]">
            <img
            src="/hr-logo.svg"
            />
            <img
            src="/hr-img.png"
            />
        </div>
        <div id="bottom" className="flex flex-col gap-4">
        <div id="title" className="font-palanquin font-regular text-[24px] leading-[100%] mq450:text-[20px]">Pays HR (Payroll)</div>
        <div id="subtitle" className="font-palanquin font-light text-[16px] leading-[150%] text-[#454545]">Dedicated tool for HR departments that simplifies payroll processes. Enables fast, secure, multisig and global instant payroll transfer to all employees simultaneously.</div>
        <button onClick={() => window.location.href='https://meetings-eu1.hubspot.com/lspirala'} className="cursor-pointer h-[48px] px-[24px] bg-[transparent] rounded-full flex flex-row items-center justify-center border-[1px] border-solid border-whitesmoke-200 hover:bg-lightgray-300 hover:box-border hover:border-[1px] hover:border-solid hover:border-lightgray-100">
            <div className="flex font-palanquin font-regular text-[16px] items-center justify-center text-gray-200 text-center">
              Book A Demo
            </div>
          </button>
          </div>
    </div>
    {/* <div id="card-pays-real-estate" className="flex flex-col w-full gap-6">
        <div id="product" className="flex flex-col items-center justify-end bg-[#F9FAFB] rounded-[24px] w-full h-[320px] pb-0 gap-[32px]">
            <img
            src="/remittance-logo.svg"
            />
            <img
            src="/wallet-img.png"
            />
        </div>
        <div id="bottom" className="flex flex-col gap-4">
        <div id="title" className="font-palanquin font-regular text-[24px] leading-[100%]">Pays Remittance</div>
        <div id="subtitle" className="font-palanquin font-light text-[16px] leading-[150%] text-[#454545]">A modern, secure and self-custodial wallet providing full control over funds. It offers access to payment cards and a simple interface as in traditional banking applications.</div>
        <button onClick={() => window.location.href='/Pays Solution - White Paper5.pdf'} className="cursor-pointer h-[48px] px-[24px] bg-[transparent] rounded-full flex flex-row items-center justify-center border-[1px] border-solid border-whitesmoke-200 hover:bg-lightgray-300 hover:box-border hover:border-[1px] hover:border-solid hover:border-lightgray-100">
            <div className="flex font-palanquin font-regular text-[16px] items-center justify-center text-gray-200 text-center">
              Book A Demo
            </div>
          </button>
          </div>
    </div> */}
    
    </div>
    </section>
  );
};

export default Products;
