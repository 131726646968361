import { FunctionComponent } from "react";
import Hero from "../components/Hero";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Companies from "../components/Companies";
import Products from "../components/Products";
import Team from "../components/Team";
import Technology from "../components/Technology";
import FAQ from "../components/FAQ";

const Desktop: FunctionComponent = () => {
  return (
    <div className="w-full max-w-[1440px] mx-auto">
      <div className="w-full relative bg-white overflow-hidden flex flex-col items-start justify-start leading-[normal] tracking-[normal] text-center text-45xl text-font-color-dark-800 font-palanquin">
        <Header />
        <Hero />
        <Companies />
        <Products />
        <Technology />
        <Team />
        <FAQ /> 
        <Footer />
      </div>
    </div>
  );
};

export default Desktop;
