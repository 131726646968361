import React, { useState, useEffect, FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import BurgerIcon from './Burger';
import classNames from 'classnames';

const Header: FunctionComponent = () => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const navigate = useNavigate();

  const handleNavigation = (hash: string, event: React.MouseEvent) => {
    navigate(`/#${hash}`);

    const element = document.getElementById(hash);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }

    setIsMenuVisible(false);
  };

  const toggleMenu = () => {
    setIsMenuVisible((prev) => !prev);
  };

  useEffect(() => {
    document.body.style.overflow = isMenuVisible ? 'hidden' : 'auto';
  }, [isMenuVisible]);

  return (
    <header className="fixed top-0 left-0 w-full z-50 bg-white flex flex-row items-center justify-center px-20 mq450:py-4 mq1200:px-[48px] mq450:p-4 box-border h-[88px] mq450:h-[72px]">
      <section className='max-w-[1280px] mx-auto w-full'>
        <div className="flex flex-row items-center justify-between gap-[20px] mq450:gap-0 mq450:m-0 w-full">
          <div className='flex flex-row items-start w-[220px]'>
            <img
              className="h-[36px] cursor-pointer"
              src="/pays-solution--logo-poziom-1@2x.png"
              onClick={() => navigate('/')}
            />
          </div>

          <div id='navbar' className='flex flex-row h-auto px-[8px] py-[8px] border-[1px] border-solid border-[#EFEFEF] rounded-full mq450:hidden'>
            <a
              href="#home"
              onClick={(e) => handleNavigation('home', e)}
              className="font-palanquin font-regular text-[16px] px-[20px] h-[32px] rounded-full items-center justify-center visited:text-[#1A1D1F] hover:bg-[#F6F6F6] no-underline text-[#1A1D1F]"
            >
              Home
            </a>
            <a
              href="#products"
              onClick={(e) => handleNavigation('products', e)}
              className="font-palanquin font-regular text-[16px] px-[20px] h-[32px] rounded-full items-center justify-center visited:text-[#1A1D1F] no-underline hover:bg-[#F6F6F6] text-[#1A1D1F]"
            >
              Products
            </a>
            <a
              href="#technology"
              onClick={(e) => handleNavigation('technology', e)}
              className="font-palanquin font-regular text-[16px] px-[20px] h-[32px] rounded-full items-center justify-center visited:text-[#1A1D1F] no-underline hover:bg-[#F6F6F6] text-[#1A1D1F]"
            >
              Technology
            </a>
            <a
              href="#team"
              onClick={(e) => handleNavigation('team', e)}
              className="font-palanquin font-regular text-[16px] px-[20px] h-[32px] rounded-full items-center justify-center visited:text-[#1A1D1F] no-underline hover:bg-[#F6F6F6] text-[#1A1D1F]"
            >
              Team
            </a>
            <a
              href="#faq"
              onClick={(e) => handleNavigation('faq', e)}
              className="font-palanquin font-regular text-[16px] px-[20px] h-[32px] rounded-full items-center justify-center visited:text-[#1A1D1F] no-underline hover:bg-[#F6F6F6] text-[#1A1D1F]"
            >
              FAQ
            </a>
          </div>

          <div className="flex flex-row w-[220px] justify-end mq450:hidden">
            <button onClick={() => window.location.href='https://meetings-eu1.hubspot.com/lspirala'} className="cursor-pointer [border:none] py-[9.5px] px-5 bg-[transparent] rounded-29xl [background:linear-gradient(180deg,_#2e79ee,_#30a7f9)] flex flex-row items-center justify-center whitespace-nowrap">
              <div className="font-palanquin font-regular text-white text-[16px]">
                Book A Demo
              </div>
            </button>
          </div>
          <div className="hidden mq450:block z-50">
            <BurgerIcon toggleMenu={toggleMenu} isMenuVisible={isMenuVisible} />
          </div>
        </div>

        <div
          className={classNames(
            'fixed top-0 bottom-0 z-40 right-0 items-start justify-center bg-white transition-all mq450:flex hidden shadow-2xl shadow-slate-700 font-medium',
            {
              'left-[200%]': !isMenuVisible,
              'left-1/2 mq450:left-1/3': isMenuVisible,
            }
          )}
        >
          <div id='navbar-mobile' className="flex flex-col items-start justify-center gap-[40px] pt-[80px]">
            <img
            className='h-[48px]'
              src='/pays-logo-mobile.svg'
            />
            <div className='flex flex-col gap-[24px] text-start'>
            <a href="#home" onClick={(e) => handleNavigation('home', e)} className="font-palanquin text-[20px] relative no-underline inline-block whitespace-nowrap text-black visited:text-black">
            Home
            </a>
            <a href="#products" onClick={(e) => handleNavigation('products', e)} className="font-palanquin  text-[20px] relative no-underline inline-block whitespace-nowrap text-black visited:text-black">
            Products
            </a>
            <a href="#technology" onClick={(e) => handleNavigation('technology', e)} className="font-palanquin  text-[20px] relative no-underline inline-block whitespace-nowrap text-black visited:text-black">
              Technology
            </a>
            <a href="#team" onClick={(e) => handleNavigation('team', e)} className="font-palanquin  text-[20px] relative no-underline whitespace-nowrap text-black visited:text-black">
              Team
            </a>
            <a href="#faq" onClick={(e) => handleNavigation('faq', e)} className="font-palanquin  text-[20px] relative no-underline inline-block whitespace-nowrap text-black visited:text-black">
              FAQ
            </a>
            </div>
          </div>
        </div>
      </section>
    </header>
  );
};

export default Header;