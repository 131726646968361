import { FunctionComponent } from "react";

const PPComponent: FunctionComponent = () => {
  return (
    <section className="self-stretch flex flex-col gap-6 justify-center py-16 px-20 mq1200:py-6 mq1200:px-8 mq450:px-4 mq450:py-6 box-border max-w-full text-left text-45xl text-font-color-dark-800 font-palanquin mq450:box-border mq800:gap-[24px] mq800:py-[83px] mq800:px-10 mq800:box-border"> 
    <div className="flex w-full justify-center text-[20px] font-medium uppercase">Privacy Policy Pays Solutions</div>
    <div id="Who Are We" className="flex flex-col gap-4">
  <div className="flex w-full justify-center text-[20px] font-medium uppercase">Who Are We?</div>
  <div className="flex flex-col gap-4 text-[16px] font-normal">
    <div>
    The administrator of your personal data is Pays Solution Technology Limited, a company incorporated and operating under the laws of the United Arab Emirates, company registration no: CL8542, registered in the Commercial Register by DIFC Free Zone with its registered office in Dubai at: Unit IH-00-VZ-01-FL-97 Level 1, Innovation Hub, Dubai International Financial Centre, Dubai, United Arab Emirates.
    <br />
    <br />
    We provide services in providing a non-custodial crypto asset wallet technology solution to store units of crypto assets and transfer units of crypto assets to other wallets.
    </div>
  </div>
    </div>
    <div id="Transparency" className="flex flex-col gap-4">
  <div className="flex w-full justify-center text-[20px] font-medium uppercase">Transparency</div>
  <div className="flex flex-col gap-4 text-[16px] font-normal">
    <div>
    Our goal is to ensure full transparency and provide you with all information regarding the processing activities we perform and the scope of the personal data we process regarding you. We endeavor to provide you with all information in a simple manner, but with all the relevant elements.
    </div>
  </div>
    </div>
    <div id="What Are Personal Data?" className="flex flex-col gap-4">
  <div className="flex w-full justify-center text-[20px] font-medium uppercase">What is personal data?</div>
  <div className="flex flex-col gap-4 text-[16px] font-normal">
    <div>
    Personal data, is any information that can lead directly or indirectly to the identification of a specific person. It can be identifiers, i.e. name, email address, identification number, location data, internet identifier but, importantly, personal data can also be information on other factors, or one or more specific factors that determine the physical, physiological, genetic, mental, economic, cultural or social identity of an individual.
    </div>
  </div>
    </div>
    <div id="For what purpose do we process your personal data?" className="flex flex-col gap-4">
  <div className="flex w-full justify-center text-[20px] font-medium uppercase">For what purpose do we process your personal data?</div>
  <div className="flex flex-col gap-4 text-[16px] font-normal">
    <div>
    We collect and process users' personal information for the safe and efficient provision of services. This will include managing user accounts, communicating with users about services, and ensuring the safety, security and integrity of our application. In addition, with personal data, we can provide users with assistance, improve services, send marketing information, prevent fraud and coordinate transactions.
    </div>
  </div>
    </div>
    <div id="Principles of personal data protection" className="flex flex-col gap-4">
  <div className="flex w-full justify-center text-[20px] font-medium uppercase">Principles of personal data protection</div>
  <div className="flex flex-col gap-4 text-[16px] font-normal">
    <div>
    a. Lawfulness, reliability, transparency - we ensure that we process personal data in accordance with generally applicable laws, taking into account the aspect of maintaining the greatest level of user privacy. We process data in such a way as not to lead to discrimination against any user, and at the same time prevent arbitrary use of your data.
    <br /><br />b. Purpose limitation - we process your personal data only for the purpose for which it was collected and about which you were informed. We do not create internal databases for our own purposes or perform any processing operations on your data that you have not consented to;
    <br /><br />c. Data minimization - we conduct a careful analysis to process only the necessary scope of data that is needed to operate the cryptocurrency wallet, to set up an account. We mainly process your name, email address, company, wallet address. We use “Single Sign-On” mechanism for logging in, which means that we do not process your login and password, and only receive an authentication token. We do not collect data for backup or for future use.
    <br /><br />d. Correctness - We make every effort to ensure that your personal information you provide is always up-to-date. If your data has been entered incorrectly or has changed, such as your name, you have the opportunity to change it each time.
    <br /><br />e. Storage limitation - we store your personal data only for the time necessary for this, which depends on your having an account and within the framework of the laws that oblige us. This means that we do not store your data for an excessive amount of time.
    </div>
  </div>
    </div>
    <div id="Rights conferred" className="flex flex-col gap-4">
  <div className="flex w-full justify-center text-[20px] font-medium uppercase">Rights conferred</div>
  <div className="flex flex-col gap-4 text-[16px] font-normal">
    <div>
    a. The right to be informed - the controller has an obligation to inform you of its identity, as well as how it processes your personal data, based on which purposes and legal grounds, your rights in connection with the processing, and other necessary elements. This is an active duty of the controller, so we make every effort to ensure that you have full knowledge of how we process your personal data, and we organize our processing so that all information concerning it is provided to you in an easily accessible manner. If the purpose of processing your personal data changes, we will inform you again.
    <br /><br />b. Right to withdraw consent - you can withdraw your consent to the processing of your personal data at any time without suffering any negative consequences. However, this does not affect the compatibility of the processing carried out on its basis.
    <br /><br />c. The right to access your personal data, including obtaining a copy of it in machine-readable format - this means that, upon your request, we are obliged to provide you with all the data that we process about you in a way that allows you to see the extent of the data.
    <br /><br />d. The right to rectify your data, including updating it - in the event that your data turns out to be incorrect or becomes outdated, we will amend or update it at your request, which will also help us to maintain the principle of correctness of the personal data we process.
    <br /><br />e. Deletion - you have the right to delete your personal data, but this is not an absolute right. We are open to receiving and processing requests in this regard and data that we process based on consent, for marketing purposes, etc. will be deleted by us. However, we are sometimes obliged to process personal data on the basis of certain legal provisions that mandate us to record data on transactions performed or as part of the investigation of claims or defense against potential claims.
    <br /><br />f. Restriction of processing - you may request restriction of processing of personal data in situations where you question the accuracy of the data, but also in situations where the processing is not lawful, but it is the user who objects to deletion and therefore requests restriction of processing, or in situations where you object to the processing of personal data until we verify whether our processing overrides the objection raised by you. 
    <br /><br />g. the right to object to processing, when it is carried out on the basis of a legitimate interest of the controller;
    <br /><br />h. right to data portability - at times you may request that the personal data processed about you be released in a structured, machine-readable format for transfer to another controller.
    <br /><br />i. right to lodge a complaint with a supervisory authority - if you consider that we are processing your personal data incorrectly or unlawfully, then you may lodge a complaint with the competent supervisory authority.
    </div>
  </div>
    </div>
    <div id="How to exercise rights?" className="flex flex-col gap-4">
  <div className="flex w-full justify-center text-[20px] font-medium uppercase">How to exercise rights?</div>
  <div className="flex flex-col gap-4 text-[16px] font-normal">
    <div>
    To exercise your rights, please contact us as follows:<br /><br />
- in writing to our registered office address: Unit IH-00-VZ-01-FL-97 Level 1, Innovation Hub, Dubai International Financial Centre, Dubai, United Arab Emirates<br />
- via email address: dpo@pays.solutions.
<br /><br />
We will respond to your request promptly, but no later than one month from the date of receipt. If we find that the request you forwarded is complex or you have made a request for the exercise of multiple rights, we may extend this period for another two months within this period, and we will inform you of this.
As a rule, the submission of requests for the realization of your rights is free of charge, however, in situations where they are continuous or clearly unreasonable, we may charge a reasonable administrative fee or refuse to process the request.
    </div>
  </div>
    </div>

    <div id="Legal basis for processing" className="flex flex-col gap-4">
  <div className="flex w-full justify-center text-[20px] font-medium uppercase">Legal basis for processing</div>
  <div className="flex flex-col gap-4 text-[16px] font-normal">
    <div>
    Consent - Your personal data may be processed on the basis of consent. It must be voluntary, specific, unambiguous and informed. This means that, as a Data Controller, we take due care to ensure that you are fully aware of what purposes your personal data will be used for under the processing consent you give. We also guarantee that your data will only be used for the purpose you have been informed about. Your consent to data processing is voluntary and depends solely on your will. We make sure that the consent is a separate statement of intent, which will be visible to the user and clearly separated from the rest of the information. We make sure that the user can give consent for a specific, single purpose, not for a wide range of different purposes. The processing of data on the basis of consent can take place in particular for such purposes as conducting marketing activities, receiving newsletters. You can always withdraw your consent as easily as you gave it, and the withdrawal will not entail any negative consequences for you. However, such action does not affect the compatibility of the processing carried out on its basis.

    <br /><br />Contract - by creating an account, you enter into a contract with us (you accept the terms and conditions, which constitute a contractual template). In the performance of the contract, we may process your personal data. However, if you are acting as a representative of the company, then your data will be processed on the basis of the legitimate interests of the administrator.
    <br /><br />Legitimate legitimate interest - we may process your personal data for our legitimate internal purposes, i.e., defense against claims, investigation of claims, fraud prevention and detection activities. For these purposes, we may keep your personal data to the extent that it is necessary for us to perform such verification. You may object to such processing of your personal data. The controller is then obliged to carry out a balancing test in which he will guess whether processing in his interest has a higher priority than the interest of the individual.
    </div>
  </div>
    </div>

    <div id="Privacy by desing i by default" className="flex flex-col gap-4">
  <div className="flex w-full justify-center text-[20px] font-medium uppercase">Privacy by desing i by default</div>
  <div className="flex flex-col gap-4 text-[16px] font-normal">
    <div>
    Our goal is to ensure the security of processed personal data by implementing adequate technical and organizational measures to maintain the availability, confidentiality and integrity of personal data and the resilience of IT systems. To ensure this, each process that involves the processing of personal data from the beginning is analyzed from the point of view of ensuring the privacy of data subjects. We apply the maximum level of confidentiality by default, including by adopting a default setting which, when you visit our application or website, will only collect the data necessary for its proper operation and the creation of an account.
    <br />
    <br />
Children's privacy - due to the nature of our application, we do not provide for the processing of children's personal data. Only adults can create an account.
    </div>
  </div>
    </div>
    <div id="Data protection officer" className="flex flex-col gap-4">
  <div className="flex w-full justify-center text-[20px] font-medium uppercase">Data protection officer</div>
  <div className="flex flex-col gap-4 text-[16px] font-normal">
    <div>
    We have appointed a data protection officer to ensure that the processing we perform complies with data protection regulations. This is your internal advisor, as well as your point of contact for all matters related to personal data processing. You can contact at the following email address: dpo@pays.solutions.
    </div>
  </div>
    </div>
    <div id="Data retention period" className="flex flex-col gap-4">
  <div className="flex w-full justify-center text-[20px] font-medium uppercase">Data retention period</div>
  <div className="flex flex-col gap-4 text-[16px] font-normal">
    <div>
    Your personal data will be processed for the duration of the obligation to store them for the purposes specified above, and thereafter for the period and to the extent required by applicable law.
    </div>
  </div>
    </div>
    <div id="Recipients of personal data" className="flex flex-col gap-4">
  <div className="flex w-full justify-center text-[20px] font-medium uppercase">Recipients of personal data</div>
  <div className="flex flex-col gap-4 text-[16px] font-normal">
    <div>
    Recipients of your personal data may be authorized authorities, courts, law firms, IT service providers, as well as other entities with whom we have entered into appropriate agreements for the entrustment of personal data processing or access.
    </div>
  </div>
    </div>
    <div id="Data processing territory" className="flex flex-col gap-4">
  <div className="flex w-full justify-center text-[20px] font-medium uppercase">Data processing territory</div>
  <div className="flex flex-col gap-4 text-[16px] font-normal">
    <div>
    Your personal data will be processed in the Emirates, as well as in the European Economic Area. If a circumstance arises in which we process personal data in another territory, we will examine the security of such transfer in advance and ensure that the technical and organizational measures we adopt will affect its security, primarily by implementing appropriate contractual clauses that obligate other entities to process data in a manner that ensures its confidentiality, availability and integrity.
    </div>
  </div>
    </div>
    <div id="Automated decision-making" className="flex flex-col gap-4">
  <div className="flex w-full justify-center text-[20px] font-medium uppercase">Automated decision-making</div>
  <div className="flex flex-col gap-4 text-[16px] font-normal">
    <div>
    Personal data will not be processed by automated means, including profiling.
    </div>
  </div>
    </div>
    <div id="Cookies" className="flex flex-col gap-4">
  <div className="flex w-full justify-center text-[20px] font-medium uppercase">Cookies</div>
  <div className="flex flex-col gap-4 text-[16px] font-normal">
    <div>
    We collect information through two channels:<br /><br />
first, information is provided directly and voluntarily by the user, in particular using the contact forms on our site;
additionally, a certain amount of information is collected during the use of the site by the user visiting us. These will include: <br /><br />
⎯ the time the server sends the response,<br />
⎯ the IP address of the user's device, <br />
⎯ the user's request (the address of the page the user accessed), <br />
⎯ information about the version of the user's web browser (web pages),<br />
⎯ information about the version of the user's operating system.<br /><br />
In case you use mobile devices when visiting our site, we further collect the following information: unique device number, ad display ID, geolocation data and other similar data on your mobile device.
<br /><br />1. As part of the operation of our services, we also use cookies stored on the user's terminal devices (smartphones, computers, tablets). Cookies (so-called “cookies”) are small text files sent by the visited website to the user's device. They enable or improve the performance of the displayed website, and help us better understand how users use our website.
<br /><br />2. Our websites use two types of cookies:
<br /><br />a. “session” - temporary files that are stored on the user's terminal device until the session expires (e.g., when the user leaves the website, deletes them or turns off the browser). When the user opens the website again, he/she will be treated as a new visitor.
<br />b. “permanent” - stored on the user's terminal device for the time specified in the parameters of cookies or until they are deleted by the user. These types of files allow us to re-identify the visitor and will allow us to tailor content to the visitor.
<br />c. “indispensable” - these are files that allow the user to move around the website.
<br />d. “advertising” - allow us to display advertisements, including personalized ones.
<br />e. “analytical” - allow analysis of user preferences.
<br /><br />3. Each user can individualize his/her browser's cookie settings. Failure to change these settings means acceptance of the cookies used in it. The Internet user can change the settings on his/her own, however, please note that restrictions on the use of certain cookies may result in incorrect operation of our website. 
<br /><br />4. Our website uses cookies for:
<br /><br />- maintain a user session on it (whose data is stored on the server and is not sent to the user's browser),
<br />- operate statistics scripts,
<br />- facilitate users' use of the website.
<br /><br />5. We use common technologies to monitor traffic on our site. We collect the following anonymous information: 
<br /><br />- number of unique hits/views,
<br />- country,
<br />- browser,
<br />- time of visit.
<br /><br />6. Data is collected using third-party vendor solutions. We currently use Google Analytics.
<br /><br />7. We reserve the right to change the Privacy Policy by publishing new content on our website or within the application.
    </div>
  </div>
    </div>

    </section>
  );
};

export default PPComponent;
