import { FunctionComponent } from "react";

const Team: FunctionComponent = () => {
  return (
    <section id="team" className="self-stretch flex flex-col items-center justify-between gap-[56px] py-[96px] px-20 mq1200:py-[64px] mq1200:px-[48px] mq450:px-4 mq450:py-8 mq450:gap-[32px] text-[#171717] box-border max-w-full text-left text-77xl mq450:text-[48px] font-palanquin">
      <div id="top" className="flex flex-col w-full items-center gap-[24px]">
        <div id="title" className="font-palanquin font-regular text-[56px] leading-[100%] tracking-[-4%] mq450:text-[28px] text-center">Pays Team</div>
      </div>
      <div id="grid" className="grid grid-cols-3 mq450:grid-cols-2 gap-6 mq450:gap-[12px] text-[24px] w-full">
        {[
          { name: "Filip Błoch", role: "CEO & Founder", img: "/filip-avatar.svg", linkedin: "https://www.linkedin.com/in/filipbloch/" },
          { name: "Adam Larsson", role: "CPO", img: "/adam-avatar.svg", linkedin: "https://www.linkedin.com/in/adam-larsson-/" },
          { name: "Michał Kramarz", role: "Advisor | Google", img: "/michal-avatar.svg", linkedin: "https://www.linkedin.com/in/mkramarz/" },
          { name: "Rafal Kittel", role: "Advisor | Paysafe Group", img: "/rafal-avatar.svg", linkedin: "https://www.linkedin.com/in/rafalkittel/" },
          { name: "Łukasz Spirala", role: "Head of Growth", img: "/lukasz-avatar.svg", linkedin: "https://www.linkedin.com/in/lukasz-spirala/" },
          { name: "Piotr Wizental", role: "Operations Manager", img: "/piotr-avatar.svg", linkedin: "https://www.linkedin.com/in/piotr-wizental/" },
          { name: "Patrycja Lisikiewicz", role: "COO", img: "/patrycja-avatar.svg", linkedin: "https://www.linkedin.com/in/patrycjalisikiewicz/" },
          { name: "Adam Jenkins", role: "Advisor | PZU TI", img: "/adam-j-avatar.svg", linkedin: "https://www.linkedin.com/in/adam-jenkins/" },
          { name: "Bartosz Gras", role: "Advisor | Gras&Partners", img: "/bartosz-avatar.svg", linkedin: "https://www.linkedin.com/in/bart-kglegal/" },
          { name: "Bil Ahmed", role: "Advisor | Microsoft", img: "/bil-avatar.svg", linkedin: "https://www.linkedin.com/in/bilahmed/" },
          { name: "Pawel Sobkow", role: "Advisor | PBpay", img: "/pawel-avatar.svg", linkedin: "https://www.linkedin.com/in/pawel-sobkow-114316/" },
          { name: "Wojciech Buła", role: "CTO", img: "/wojciech-avatar.svg", linkedin: "https://www.linkedin.com/in/wpbula/" },
          { name: "Nilesh Harish Thakkar", role: "BDM", img: "/nilesh-avatar.svg", linkedin: "https://linkedin.com/in/nilesh-harish-thakkar" }
        ].map((member, index) => (
          <div key={index} className="flex flex-row mq450:flex-col items-center p-6 mq450:p-4 rounded-[24px] mq450:rounded-[16px] gap-4 w-auto h-auto border-[1px] border-solid border-[#EFEFEF]">
            <div
              id="photo"
              className="relative flex items-end w-[96px] mq450:w-[64px] cursor-pointer"
              onClick={() => window.open(member.linkedin, "_blank")}
            >
              <img className="rounded-full h-[96px] w-[96px] mq450:h-[64px] mq450:w-[64px]" src={member.img} alt={`${member.name} avatar`} />
              <img className="absolute bottom-0 right-0 transform-translate-x-4 mq450:translate-x-0 h-[32px] mq450:h-[24px]" src="/li-logo.svg" alt="LinkedIn logo" />
            </div>
            <div id="text" className="flex flex-col gap-2 mq450:gap-1.5 items-start mq450:items-center mq450:text-center w-full">
              <div id="name" className="font-palanquin font-medium text-[24px] mq450:text-[18px] leading-[100%]">{member.name}</div>
              <div id="role" className="font-palanquin font-regular text-[20px] mq450:text-[16px] text-[#454545] leading-[150%]">{member.role}</div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Team;